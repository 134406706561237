import React, { useState } from "react"
import Layout from "../components/layout"

export default function UVUAuth() {
  const [id, setId] = useState("")
  const [processing, setProcessing] = useState(false)

  function handleIdSubmit(id) {
    if (processing) {
      return
    }

    setProcessing(true)

    fetch(`${process.env.GATSBY_FIREBASE_URI}/auth_uvu`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ticket: id + "" }),
    })
      .then(res => res.json())
      .then(data => {
        const { authenticated, error } = data
        if (authenticated || error === "has taken survey") {
          window.location.replace(
            `${process.env.GATSBY_MATCHMAKER_URI}?ticket=${id}&school=UVU`
          )
        } else {
          alert("Verifying...please enter your ID number again to confirm.")
          setId("")
        }

        setProcessing(false)
      })
  }

  return (
    <Layout>
      <div className="h-screen justify-center flex items-center">
        <form
          className="uvu-form flex flex-col items-center justify-center w-2/3 md:w-2/5 h-48"
          style={{ backgroundColor: "#275D38" }}
        >
          <p className="text-lg md:text-4xl text-normal text-white">
            Are you a UVU Student?
          </p>
          <label
            className="text-sm md:text-2xl text-normal text-white"
            htmlFor="uvuid"
          >
            What is your UVU ID?
          </label>
          <input
            className="outline-black focus:border-white-300 text-center my-4"
            type="text"
            id="uvuid"
            placeholder="Enter UVU ID here"
            value={id}
            onChange={e => setId(e.target.value)}
          ></input>
          <input
            type="button"
            value={processing ? "verifying..." : "verify"}
            className="px-2 text-white my-2"
            style={{ border: "2px solid #ffffff" }}
            onClick={() => handleIdSubmit(id)}
          ></input>
        </form>
      </div>
    </Layout>
  )
}
